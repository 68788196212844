<template>
  <layout>
    <template v-if="this.$route.name == 'add-faq'">
      <PageHeader :title="title" :items="items" />
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items" />
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label>Question <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Name"
                  v-model="form.question"
                  :class="{
                    'is-invalid': submitted && $v.form.question.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.question.required"
                  class="invalid-feedback"
                >
                  Question is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label>Answer <span style="color: red">*</span></label>
                <ckeditor
                  v-model="form.answer"
                  :editor="editor"
                  :class="{
                    'is-invalid': submitted && $v.form.answer.$error,
                  }"
                ></ckeditor>
                <div
                  v-if="submitted && !$v.form.answer.required"
                  class="invalid-feedback"
                >
                  Answer is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <b-form-checkbox v-model="form.is_active"
                  >is active
                </b-form-checkbox>
              </b-form-group>

              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-faq'">Save Data</span>
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import faqMixin from "../../../mixins/ModuleJs/member-faq";
import { required } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  mixins: [MixinRequest, faqMixin],
  data() {
    return {
      editor: ClassicEditor,
      title: "ADD FAQ",
      title2: "EDIT FAQ",
      items: [
        {
          text: "Back",
          href: `/memebr-faq/${this.$route.params.lead_id}`,
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      question: { required },
      answer: { required },
    },
  },
};
</script>
