import { pancard } from "../../js/path";
export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "member_name",
          label: "Member",
        },
        {
          key: "pan_card",
          label: "Pancard",
        },
        {
          key: "bank_name",
        },
        {
          key: "account_holder_name",
        },
        {
          key: "account_no",
        },
        {
          key: "ifsc_code",
        },
        {
          key: "branch_name",
        },
        {
          key: "agreement",
        },
        {
          key: "pan_card_image",
        },
        {
          key: "bank_cheque_image",
        },
        {
          key: "verification_status",
          label: "Status",
        },
        {
          key: "is_reject",
          label: "Rejected reasons",
        },
        {
          key: "upload",
        },
      ],
      form: {
        id: null,
        verification_status: null,
        reason: "",
      },
      showVerificationModal: false,
      showRejectReasonModal: false,
      rejectReasons: [],
      panImg: null,
      imageModal: false,
      uploadimageModal: false,
      image: null,
      bank_chqeue_image: null,
    };
  },

  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    verificationModal(item) {
      this.form.id = item.id;
      this.panImg = item.pan_card;
      this.showVerificationModal = true;
    },

    clearVerificationModel() {
      this.showVerificationModal = false;
      this.form.reason = "";
      this.form.verification_status = null;
      this.panImg = null;
    },

    RejectReasonsModal(id) {
      this.form.id = id;
      this.showRejectReasonModal = true;
      this.fetchreasons();
    },

    clearReasonModel() {
      this.showRejectReasonModal = false;
      this.form.id = "";
    },

    showImageModal(img) {
      this.imageModal = true;
      this.image = img;
    },

    uploadImageModal(id, img) {
      this.uploadimageModal = true;
      this.bank_chqeue_image = img;
      this.form.id = id;
    },

    clearImageModal() {
      this.imageModal = false;
      this.image = null;
    },

    clearUploadImageModal() {
      this.uploadimageModal = false;
      this.bank_chqeue_image = null;
      this.form.id = "";
    },

    handleVerificationChange() {
      this.form.reason = "";
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        const maxSize = 150 * 1024; // 150 KB

        // Check if the file type is valid
        if (!allowedTypes.includes(file.type)) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Only .jpg, .jpeg, .png allowed",
          });
          this.$refs.fileInput.value = "";
        }
        // Check if the file size is greater than 150 KB
        else if (file.size > maxSize) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Max file size should be 150 KB",
          });
          this.$refs.fileInput.value = "";
        } else {
          console.log("File is valid:", file);
          this.bank_chqeue_image = file ?? "";
        }
      }
    },

    checkNoteValidity() {
      if (this.form.reason == "") {
        this.submitted = true;
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Note Filed Required",
        });
        return false;
      }
      return true;
    },

    async SumbitVerificationStatus() {
      try {
        if (this.form.verification_status === 1) {
          this.$store.commit("loader/updateStatus", true);
          console.log("submited");
          const index = this.tableData.data.findIndex(
            (e) => e.id == this.form.id
          );
          const url = pancard.updateVerificationStatus + `/${this.form.id}`;

          const dataAppend = new FormData();
          for (var key in this.form) {
            if (key !== "id" && key !== "reason") {
              dataAppend.append(key, this.form[key]);
            }
          }
          const res = await this.postRequest(url, dataAppend);
          console.log(res);
          if (res.status) {
            this.tableData.data[index].verification_status = 1;
            this.showVerificationModal = false;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: res.message,
            });
          }
        } else {
          let validateForm = this.checkNoteValidity();
          if (!validateForm) {
            return;
          } else {
            this.$store.commit("loader/updateStatus", true);
            const index = this.tableData.data.findIndex(
              (e) => e.id == this.form.id
            );
            const url = pancard.updateVerificationStatus + `/${this.form.id}`;

            const dataAppend = new FormData();
            for (var k in this.form) {
              if (k !== "id") {
                dataAppend.append(k, this.form[k]);
              }
            }

            const res = await this.postRequest(url, dataAppend);
            if (res.status) {
              this.tableData.data[index].verification_status = 3;
              this.showVerificationModal = false;
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: res.message,
              });
            }
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      this.status = "";
      if (txt == "verified") {
        this.activeTab = txt;
        this.status  = 1;
      } else if (txt == "rejected") {
        this.activeTab = txt;
        this.status  = 3;
      } else if (txt == "pending") {
        this.activeTab = txt;
        this.status  = 2;
      }else if (txt == "all") {
        this.activeTab = txt;
      }
      let url = `${pancard.getPancard}?page=${this.currentPage ?? 1}&q=${
        this.filter ?? ""
      }&status=${
        this.status ?? ""
      }`;
      try {
        const res = await this.getRequest(url);
        if (res) {
          this.tableData = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async updateData() {
      try {
        let dataAppend = new FormData();
        if (this.bank_chqeue_image) {
          dataAppend.append("bank_cheque_image", this.bank_chqeue_image);
          dataAppend.append("_method", "put");
        }
        let url = `${pancard.getPancard}/${this.form.id}`;
        const data = await this.postRequest(url, dataAppend);
        if (data?.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data?.message ?? "Data updated successfully",
          });
          this.image = null;
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Something went wrong",
          });
        }
        this.$nextTick(() => {
          this.$bvModal.hide("uploadimageModal");
        });
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
        this.$nextTick(() => {
          this.$bvModal.hide("uploadimageModal");
        });
      }
      this.uploadimageModal = false;
      this.fetchData();
    },

    async fetchreasons() {
      this.$store.commit("loader/updateStatus", true);

      let url = `${pancard.getPancard}/${this.form.id}`;
      try {
        const res = await this.getRequest(url);
        if (res) {
          this.rejectReasons = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
};
