import {
    patient_library
  } from "../../js/path";
  
  export default {
    data() {
      return {
        isPaginate: true,
        pageNo: null,
        table_header: [],
        subCategory_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "title",
            label: "Title"
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        categoryDropdown: [],
        form: {
          title: "",
          is_active: 0
        },
        image: "",
        img_url: "",
        edit: {
          img_url: null,
        },
      };
    },
    computed: {
      
    },
    methods: {
      readFile(e, txt) {
        if (txt == "image") {
          this.image = e.target.files[0];
          if (this.image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.image.reset();
            this.img_url = "";
            this.edit.img_url = "";
            this.image = "";
            return false;
          } else {
            this.img_url = URL.createObjectURL(this.image);
            this.edit.img_url = "";
            return true;
          }
        } 
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        this.isPaginate = false;
        this.showData = false;
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library.patientLibrarySubCategoryUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library.patientLibrarySubCategoryUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library.patientLibrarySubCategoryUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library.patientLibrarySubCategoryUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library.patientLibrarySubCategoryUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library.patientLibrarySubCategoryUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          this.isPaginate = true;
          this.showData = true;
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async fetchCategory() {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = patient_library.patientLibraryCategoryUrl + '?filter=active';
          const data = await this.getRequest(url);
          this.categoryDropdown = data.response.data.map((item) => ({
            key: item.id, // Value field
            label: item.title, // Text field
          }));
        } catch (error) {
          console.error('Error fetching dropdown data:', error);
        }
      },
      async updateStatus(id) {
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library.patientLibrarySubCategoryupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
          });
          if (data.status) {
            const responseData = data.response;
            this.tableData.data[index] = responseData;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      },
      async fetchpatient_library_subCategory(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = patient_library.patientLibrarySubCategoryUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.tile != "" && responseData.title != null) {
              this.form.title = responseData.title;
            }
            if (responseData.is_active) {
              this.form.is_active = true;
            }
            this.form.category_id = {
              key: responseData?.category_id,
              label: responseData?.category_name
            }
            this.subCategory_id = responseData.id;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async deletepatient_library_subCategory(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library.patientLibrarySubCategoryUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restorepatient_library_subCategory(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library.restorepatientLibrarySubCategory + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = patient_library.patientLibrarySubCategoryUrl;
          if (this.$route.name == "edit-subcategory") {
            url = patient_library.patientLibrarySubCategoryUrl + "/" + this.subCategory_id;
          }
          let dataAppend = new FormData();
  
          if (this.form.is_active) {
            dataAppend.append("is_active", 1);
          } else {
            dataAppend.append("is_active", 0);
          }

          if (this.form.category_id) {
            // console.log(this.form.campaign_id);
            dataAppend.append("category_id", this.form.category_id.key);
          }
  
          for (var key in this.form) {
            if (
              key != "is_active" &&
              key != "category_id" &&
              key != "category_name"
            ) {
              dataAppend.append(key, this.form[key]);
            }
          }
  
          if (this.$route.name == "edit-subcategory") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/patient-library/sub-categories");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //        if (this.table_header.includes("delete")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           this.fields[index] = {
    //              key: "restore",
    //           };
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "delete"
    //           );
    //           this.table_header[table_index] = "restore";
    //        } else {
    //           this.table_header.push("restore");
    //           this.fields.push({
    //              key: "restore",
    //           });
    //        }
  
    //        if (!this.can("restore-campaign_content")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "restore"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "restore"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        }
  
    //        let index = this.fields.findIndex((item) => item.key == "edit");
    //        if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //        }
    //     } else {
    //        if (this.table_header.includes("restore")) {
    //           if (!this.can("edit-campaign_content")) {
    //              this.fields[6] = {
    //                 key: "delete",
    //              };
    //           } else {
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
  
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "restore"
    //           );
    //           this.table_header[table_index] = "delete";
    //        }
  
    //        if (!this.can("edit-campaign_content")) {
    //           let index = this.fields.findIndex((item) => item.key == "edit");
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "edit"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("edit")) {
    //              this.table_header.push("edit");
    //              this.fields[6] = {
    //                 key: "edit",
    //              };
    //           }
    //        }
  
    //        if (!this.can("delete-campaign_content")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "delete"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("delete")) {
    //              this.table_header.push("delete");
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
    //        }
    //     }
    //  },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      // activeTab(v) {
        // if (v) this.tabActive();
      // },
      "form.content_type"(v) {
        if (v == "pop_up") {
          this.form.ad_position = 1;
        }
      },
    },
    created() {
      if (
        this.$route.name == "add-subcategory" ||
        this.$route.name == "edit-subcategory"
      ) {
        if (this.$route.name == "edit-subcategory") {
          this.fetchpatient_library_subCategory(this.$route.params.id);
        }
      } else {
        this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
    },
  };
  