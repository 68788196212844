<template>
    <Layout>
       <template v-if="this.$route.name == 'add-subcategory'">
          <PageHeader :title="title1" :items="items"></PageHeader>
       </template>
       <template v-else>
          <PageHeader :title="title2" :items="items"></PageHeader>
       </template>
       <div class="row">
          <div class="col-12">
             <div class="card">
                <div class="card-body">
                   <b-form enctype="multipart/form-data" ref="campaign">
                    <div class="row">
                        <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                         <label for="input-1">Sub Category Name
                            <span style="color: red">*</span></label>
                         <b-form-input id="input-1" v-model="form.title" placeholder="Enter Sub Category Name" :class="{
                            'is-invalid': submitted && $v.form.title.$error,
                         }"></b-form-input>
                         <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                            Sub Category title is required.
                         </div>
                      </b-form-group>
                      <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                      <label for="type">Category </label>
                          <multiselect
                              @search-change="fetchCategory"
                              id="input-multi"
                              v-model="form.category_id"
                              :options="categoryDropdown"
                              :multiple="false"
                              track-by="key"
                              placeholder="Type here to search"
                              label="label"
                          >
                              <span slot="noOptions">Type here to search</span>
                          </multiselect>
                  </b-form-group>
                    </div>
                      <b-button type="button" variant="success" @click="submitData">
                         <span v-if="this.$route.name == 'add-subcategory'">Save Data</span>
                         <span v-else>Update Data</span>
                      </b-button>
                   </b-form>
                </div>
             </div>
          </div>
       </div>
    </Layout>
 </template>
 
 <script>
 import Layout from "../../../layouts/main";
 import PageHeader from "@/components/page-header";
 import MixinRequest from "../../../../mixins/request";
 import languageMixin from "../../../../mixins/ModuleJs/patient-lib-sub-category";
 import { required } from "vuelidate/lib/validators";
 import Multiselect from "vue-multiselect";
 
 export default {
    data() {
       return {
          submitted: false,
          title1: "Add Sub Category",
          title2: "Edit Sub Category",
          items: [
             {
                text: "Back",
                href: "/patient-library/languages",
             },
             {
                text: "Data",
             },
          ],
       };
    },
    components: {
        Layout,
        PageHeader,
        Multiselect
    },
    mixins: [languageMixin, MixinRequest],
    validations: {
       form: {
          title: { required },
       },
    },
 };
 </script>
 
 