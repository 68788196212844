import {
    patient_library
  } from "../../js/path";
  
  export default {
    data() {
      return {
        isPaginate: true,
        pageNo: null,
        table_header: [],
        category_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "title",
            label: "Title"
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        form: {
          title: "",
          is_active: 0
        },
      };
    },
    computed: {
      
    },
    methods: {
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        this.isPaginate = false;
        this.showData = false;
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library.patientLibraryCategoryUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library.patientLibraryCategoryUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library.patientLibraryCategoryUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library.patientLibraryCategoryUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library.patientLibraryCategoryUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library.patientLibraryCategoryUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          this.isPaginate = true;
          this.showData = true;
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async updateStatus(id) {
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library.patientLibraryCategoryupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
          });
          if (data.status) {
            const responseData = data.response;
            this.tableData.data[index] = responseData;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      },
      async fetchpatient_library_category(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = patient_library.patientLibraryCategoryUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.tile != "" && responseData.title != null) {
              this.form.title = responseData.title;
            }
            if (responseData.is_active) {
              this.form.is_active = true;
            }
            this.category_id = responseData.id;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async deletepatient_library_category(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library.patientLibraryCategoryUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restorepatient_library_category(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library.restorepatientLibraryCategory + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = patient_library.patientLibraryCategoryUrl;
          if (this.$route.name == "edit-category") {
            url = patient_library.patientLibraryCategoryUrl + "/" + this.category_id;
          }
          let dataAppend = new FormData();
  
          if (this.form.is_active) {
            dataAppend.append("is_active", 1);
          } else {
            dataAppend.append("is_active", 0);
          }
  
          for (var key in this.form) {
            if (
              key != "is_active"
            ) {
              dataAppend.append(key, this.form[key]);
            }
          }
  
          if (this.$route.name == "edit-category") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/patient-library/categories");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //        if (this.table_header.includes("delete")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           this.fields[index] = {
    //              key: "restore",
    //           };
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "delete"
    //           );
    //           this.table_header[table_index] = "restore";
    //        } else {
    //           this.table_header.push("restore");
    //           this.fields.push({
    //              key: "restore",
    //           });
    //        }
  
    //        if (!this.can("restore-campaign_content")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "restore"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "restore"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        }
  
    //        let index = this.fields.findIndex((item) => item.key == "edit");
    //        if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //        }
    //     } else {
    //        if (this.table_header.includes("restore")) {
    //           if (!this.can("edit-campaign_content")) {
    //              this.fields[6] = {
    //                 key: "delete",
    //              };
    //           } else {
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
  
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "restore"
    //           );
    //           this.table_header[table_index] = "delete";
    //        }
  
    //        if (!this.can("edit-campaign_content")) {
    //           let index = this.fields.findIndex((item) => item.key == "edit");
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "edit"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("edit")) {
    //              this.table_header.push("edit");
    //              this.fields[6] = {
    //                 key: "edit",
    //              };
    //           }
    //        }
  
    //        if (!this.can("delete-campaign_content")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "delete"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("delete")) {
    //              this.table_header.push("delete");
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
    //        }
    //     }
    //  },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
    },
    created() {
      if (
        this.$route.name == "add-category" ||
        this.$route.name == "edit-category"
      ) {
        if (this.$route.name == "edit-category") {
          this.fetchpatient_library_category(this.$route.params.id);
        }
      } else {
        this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
    },
  };
  