<template>
    <Layout>
       <template v-if="this.$route.name == 'add-category'">
          <PageHeader :title="title1" :items="items"></PageHeader>
       </template>
       <template v-else>
          <PageHeader :title="title2" :items="items"></PageHeader>
       </template>
       <div class="row">
          <div class="col-12">
             <div class="card">
                <div class="card-body">
                   <b-form enctype="multipart/form-data" ref="campaign">
                      <b-form-group id="input-group-1">
                         <label for="input-1">Category Name
                            <span style="color: red">*</span></label>
                         <b-form-input id="input-1" v-model="form.title" placeholder="Enter Campaign Name" :class="{
                            'is-invalid': submitted && $v.form.title.$error,
                         }"></b-form-input>
                         <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                            Category title is required.
                         </div>
                      </b-form-group>
                      <b-button type="button" variant="success" @click="submitData">
                         <span v-if="this.$route.name == 'add-category'">Save Data</span>
                         <span v-else>Update Data</span>
                      </b-button>
                   </b-form>
                </div>
             </div>
          </div>
       </div>
    </Layout>
 </template>
 
 <script>
 import Layout from "../../../layouts/main";
 import PageHeader from "@/components/page-header";
 import MixinRequest from "../../../../mixins/request";
 import campaignMixin from "../../../../mixins/ModuleJs/patient-lib-category";
 import { required } from "vuelidate/lib/validators";
 
 export default {
    data() {
       return {
          submitted: false,
          title1: "Add Category",
          title2: "Edit Category",
          items: [
             {
                text: "Back",
                href: "/patient-library/categories",
             },
             {
                text: "Data",
             },
          ],
       };
    },
    components: {
       Layout,
       PageHeader,
    },
    mixins: [campaignMixin, MixinRequest],
    validations: {
       form: {
          title: { required },
       },
    },
 };
 </script>
 
 