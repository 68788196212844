<template>
  <Layout>
    <template v-if="this.$route.name == 'add-forum'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form
              enctype="multipart/form-data"
              ref="forum"
              @submit.prevent="
                this.$route.name == 'add-forum' ? 'Save Data' : 'Update Data'
              "
            >
              <div class="row">
                <b-form-group class="col-6">
                  <label for="input-3"
                    >Forum Name <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-3"
                    v-model="form.name"
                    placeholder="Enter Forum Name"
                    @keyup.prevent="slugify"
                    :class="{
                      'is-invalid': submitted && $v.form.name.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.name.required"
                    class="invalid-feedback"
                  >
                    Name is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-4"
                    >Forum Link Name <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-4"
                    v-model="form.link_name"
                    placeholder="Enter Forum Link Name"
                    :class="{
                      'is-invalid': submitted && $v.form.link_name.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.name.required"
                    class="invalid-feedback"
                  >
                    Forum Link Name is required.
                  </div>
                </b-form-group>
                <b-form-group
                  label="Tell Us More About The Forum"
                  label-for="input-26"
                  class="col-12"
                >
                  <ckeditor
                    v-model="form.description"
                    :editor="editor"
                  ></ckeditor>
                </b-form-group>
                <b-form-group class="col-12">
                  <label for="type"
                    >Forum Type <span style="color: red">*</span></label
                  >
                  <b-form-select
                    @input="changeForumType"
                    id="type"
                    v-model="form.forum_type"
                    value-field="value"
                    text-field="text"
                    :options="discussion_forum_type"
                    :class="{
                      'is-invalid': submitted && $v.form.forum_type.$error,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.forum_type.required"
                    class="invalid-feedback"
                  >
                    Forum Type is required.
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="form.forum_type == 'partner'"
                  :label="form.forum_type == 'partner' ? 'Partner' : ''"
                  label-for="forum-type"
                  class="col-12"
                >
                  <b-form-select
                    id="forum-type"
                    v-model="form.partner_id"
                    :options="form.forum_type == 'partner' ? partner : ''"
                    value-field="id"
                    text-field="title"
                    :class="{
                      'is-invalid': submitted && $v.form.partner_id.$error,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.partner_id.required"
                    class="invalid-feedback"
                  >
                    Partner is required when Forum Type is Partner.
                  </div>
                </b-form-group>
                <b-form-group id="input-2" class="col-12">
                  <label for="input-grp-visible"
                    >Forum Visibility <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="input-grp-visible"
                    v-model="form.forum_visibility"
                    value-field="value"
                    text-field="text"
                    :options="discussion_forum_visibility"
                    :class="{
                      'is-invalid':
                        submitted && $v.form.forum_visibility.$error,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.forum_visibility.required"
                    class="invalid-feedback"
                  >
                    Forum Visibility is required.
                  </div>
                </b-form-group>

                <b-form-group
                  v-if="form.forum_visibility == 'private'"
                  label-for="checkbox-group-1"
                  v-slot="{ ariaDescribedby }"
                  class="col-12"
                >
                  <label for="checkbox-group-1"
                    >Forum Privacy Rules
                    <span style="color: red">*</span></label
                  >
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="form.privacy_rule"
                    :options="forum_privacy_rules"
                    :aria-describedby="ariaDescribedby"
                    :class="{
                      'is-invalid': submitted && $v.form.privacy_rule.$error,
                    }"
                  >
                  </b-form-checkbox-group>
                  <div
                    v-if="submitted && !$v.form.privacy_rule.required"
                    class="invalid-feedback"
                  >
                    Forum Privacy Rule is required.
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-12"
                  v-if="
                    form.privacy_rule.includes('password') &&
                    form.forum_visibility == 'private'
                  "
                  id="input-group-password"
                >
                  <label for="input-password"
                    >Password <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-password"
                    v-model="form.rule_type_password"
                    placeholder="Enter Password"
                    :class="{
                      'is-invalid':
                        submitted && $v.form.rule_type_password.$error,
                    }"
                  >
                  </b-form-input>
                  <div
                    v-if="submitted && !$v.form.rule_type_password.required"
                    class="invalid-feedback"
                  >
                    Password is required when Forum Privacy Rules is set to
                    Password.
                  </div>
                </b-form-group>

                <b-form-group
                  class="position-relative col-12"
                  id="input-group-list-user"
                  v-if="
                    form.privacy_rule.includes('invitation') &&
                    form.forum_visibility == 'private'
                  "
                >
                  <label for="input-list-user"
                    >Upload List of Users
                    <span style="color: red">* </span></label
                  >
                  <div style="position: absolute; top: 0; left: 160px">
                    <a href="/invitation.xlsx" target="_blank">
                      Download Sample File
                    </a>
                  </div>
                  <b-form-file
                    id="input-list-user"
                    accept=".csv"
                    placeholder="Choose a file or drop it here... [Upload Max File Size : 2MB]"
                    @change="readListOfUser($event, 'invitation_file')"
                  ></b-form-file>
                </b-form-group>

                <b-form-group
                  id="input-group-17"
                  label="Brand Page Link Text"
                  label-for="input-17"
                  class="col-6 position-relative"
                >
                  <b-form-input
                    id="input-17"
                    :maxlength="250"
                    v-model="form.brand_page_link_text"
                    placeholder="Enter Brand Page Link Text"
                  ></b-form-input>
                  <div class="text-right">
                    <p
                      v-if="form.brand_page_link_text"
                      class="badge position-absolute"
                      style="top: 4px; right: 13px"
                      :class="{
                        'badge-success':
                          form.brand_page_link_text.length !== 250,
                        'badge-danger':
                          form.brand_page_link_text.length === 250,
                      }"
                    >
                      You typed
                      {{ form.brand_page_link_text.length }} out of 250 chars.
                    </p>
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-18"
                  label="Brand Page Link"
                  label-for="input-18"
                  class="col-6 position-relative"
                >
                  <b-form-input
                    id="input-18"
                    v-model="form.brand_page_link"
                    placeholder="Enter Brand Page Link"
                  >
                  </b-form-input>
                  <div class="text-right">
                    <p
                      v-if="form.brand_page_link"
                      class="badge position-absolute"
                      style="top: 4px; right: 13px"
                      :class="{
                        'badge-success': form.brand_page_link.length !== 250,
                        'badge-danger': form.brand_page_link.length === 250,
                      }"
                    >
                      You typed
                      {{ form.brand_page_link.length }} out of 250 chars.
                    </p>
                  </div>
                </b-form-group>

                <b-form-group
                  id="input-group-23"
                  label="Thumbnail Image [Upload Max File Size : 2MB]"
                  label-for="input-23"
                  class="col-6"
                >
                  <b-form-file
                    id="input-23"
                    accept=".jpg, .png, .jpeg, .svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'thumbnail_image')"
                    ref="thumbnail_image"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-forum' && edit.thumbnail_image_url
                    "
                  >
                    <img
                      :src="edit.thumbnail_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="thumbnail_image_url">
                    <img
                      :src="thumbnail_image_url"
                      width="128px"
                      height="128px"
                      ref="thumbnail_image_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  id="input-group-24"
                  label="Thumbnail Image Logo [Upload Max File Size : 2MB]"
                  label-for="input-24"
                  class="col-6"
                >
                  <b-form-file
                    id="input-24"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'thumbnail_image_logo')"
                    ref="thumbnail_image_logo"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-forum' &&
                      edit.thumbnail_image_logo_url
                    "
                  >
                    <img
                      :src="edit.thumbnail_image_logo_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="thumbnail_image_logo_url">
                    <img
                      :src="thumbnail_image_logo_url"
                      width="128px"
                      height="128px"
                      ref="thumbnail_image_logo_url"
                    />
                  </template>
                </b-form-group>

                <b-form-group
                  id="input-group-19"
                  label="App link Image [Upload Max File Size : 2MB]"
                  label-for="input-19"
                  class="col-6"
                >
                  <b-form-file
                    id="input-19"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name')"
                    ref="image_name"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-forum' && edit.image_name_url"
                  >
                    <img
                      :src="edit.image_name_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="image_name_url">
                    <img
                      :src="image_name_url"
                      width="128px"
                      height="128px"
                      ref="image_name_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-6"
                  label="Website Link Image [Upload Max File Size : 2MB]"
                  label-for="input-5"
                >
                  <b-form-file
                    id="input-5"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'website_banner_image')"
                    ref="website_banner_image"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-forum' &&
                      edit.website_banner_image_url
                    "
                  >
                    <img
                      :src="edit.website_banner_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="website_banner_image_url">
                    <img
                      :src="website_banner_image_url"
                      width="128px"
                      height="128px"
                      ref="website_banner_image_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>

                <b-form-group
                  id="input-group-21"
                  label="Pre Login Image [Upload Max File Size : 2MB]"
                  label-for="input-21"
                  class="col-6"
                >
                  <b-form-file
                    id="input-21"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'pre_login_image')"
                    ref="pre_login_image"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-forum' && edit.pre_login_image_url
                    "
                  >
                    <img
                      :src="edit.pre_login_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="pre_login_image_url">
                    <img
                      :src="pre_login_image_url"
                      width="128px"
                      height="128px"
                      ref="pre_login_image_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  id="input-group-22"
                  label="Mobile Screen Hand Banner [Upload Max File Size : 2MB]"
                  label-for="input-22"
                  class="col-6"
                >
                  <b-form-file
                    id="input-22"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'pre_login_image2')"
                    ref="pre_login_image2"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-forum' && edit.pre_login_image2_url
                    "
                  >
                    <img
                      :src="edit.pre_login_image2_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="pre_login_image2_url">
                    <img
                      :src="pre_login_image2_url"
                      width="128px"
                      height="128px"
                      ref="pre_login_image2_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>

                <b-form-group
                  id="input-group-5"
                  label-for="input-geo"
                  label="Select Geographic"
                  v-slot="{ ariaDescribedby }"
                  class="col-12"
                >
                  <b-form-radio-group
                    id="input-geo"
                    v-model="form.geographic_type"
                    :options="forum_geographic"
                    :aria-describedby="ariaDescribedby"
                    name="geographic"
                    @change="changeGeographicType"
                  ></b-form-radio-group>
                </b-form-group>
                <template>
                  <b-form-group
                    v-if="form.geographic_type == 'country'"
                    class="col-12"
                  >
                    <label for="input-multi"
                      >Country <span style="color: red">*</span></label
                    >
                    <multiselect
                      id="input-multi"
                      v-model="form.country"
                      :options="allCountry"
                      :multiple="true"
                      track-by="name"
                      label="name"
                      :class="{
                        'is-invalid': submitted && $v.form.country.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitted && !$v.form.country.required"
                      class="invalid-feedback"
                    >
                      Country is required.
                    </div>
                  </b-form-group>
                  <b-form-group
                    label-for="input-country"
                    label="Country"
                    class="col-12"
                    v-if="form.geographic_type != 'country'"
                  >
                    <b-form-select
                      id="input-country"
                      v-model="form.country"
                      :options="allCountry"
                      value-field="name"
                      text-field="name"
                      @change="getStatesByCountry"
                      :class="{
                        'is-invalid': submitted && $v.form.country.$error,
                      }"
                    >
                    </b-form-select>
                    <div
                      v-if="submitted && !$v.form.country.required"
                      class="invalid-feedback"
                    >
                      Country is required.
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-for="input-grp-state-multi"
                    label="State"
                    class="col-12"
                    v-if="form.geographic_type == 'state'"
                  >
                    <multiselect
                      id="input-grp-state-multi"
                      v-model="form.state"
                      :options="allState"
                      :multiple="true"
                      track-by="state"
                      label="state"
                      :class="{
                        'is-invalid': submitted && $v.form.state.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitted && !$v.form.state.required"
                      class="invalid-feedback"
                    >
                      State is required.
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-for="input-grp-state2"
                    label="State"
                    class="col-12"
                    v-if="form.geographic_type == 'city'"
                  >
                    <b-form-select
                      id="input-grp-state2"
                      v-model="form.state"
                      :options="allState"
                      value-field="state"
                      text-field="state"
                      @change="getCitiesByState"
                      :class="{
                        'is-invalid': submitted && $v.form.state.$error,
                      }"
                    >
                    </b-form-select>
                    <div
                      v-if="submitted && !$v.form.state.required"
                      class="invalid-feedback"
                    >
                      State is required.
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-for="input-grp-city"
                    label="City"
                    class="col-12"
                    v-if="form.geographic_type == 'city'"
                  >
                    <multiselect
                      id="input-grp-city"
                      v-model="form.city"
                      :options="allCity"
                      :multiple="true"
                      track-by="city"
                      label="city"
                      :class="{
                        'is-invalid': submitted && $v.form.city.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitted && !$v.form.city.required"
                      class="invalid-feedback"
                    >
                      City is required.
                    </div>
                  </b-form-group>
                </template>

                <b-form-group label-for="input-grp-6" class="col-6">
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <label for="comm" class="mb-0">Community</label>
                    <b-form-checkbox
                      v-model="status"
                      button
                      button-variant="info"
                      size="sm"
                    >
                      <template v-if="status">Unselect All</template>
                      <template v-else>Select All</template>
                    </b-form-checkbox>
                  </div>
                  <multiselect
                    id="input-grp-6"
                    @search-change="fetchCommunity"
                    v-model="form.community"
                    :options="community"
                    :multiple="true"
                    track-by="id"
                    label="title"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label-for="input-grp-7"
                  label="User Type"
                  class="col-6"
                >
                  <multiselect
                    id="input-grp-7"
                    v-model="form.user_types"
                    :options="user_types"
                    :multiple="true"
                    track-by="value"
                    label="name"
                    style="margin-top: 15px"
                  >
                  </multiselect>
                </b-form-group>

                <b-form-group id="example text" class="col-6 mb-0">
                  <div class="forum_expert">
                    <label for="text1" class="mb-0">Expert</label>
                    <b-form-input
                      for="text1"
                      v-model="form.forum_council_expert_text"
                    ></b-form-input>
                  </div>
                </b-form-group>

                <b-form-group id="example text 2" class="col-6 forum_expert">
                  <div class="forum_expert">
                    <label for="text2" class="mb-0">Expert</label>
                    <b-form-input
                      for="text2"
                      v-model="form.forum_other_expert_text"
                    ></b-form-input>
                  </div>
                </b-form-group>

                <div class="col-6">
                  <multiselect
                    @search-change="fetchExpert"
                    class="mb-2"
                    id="input-grp-28"
                    v-model="form.council_experts"
                    :options="forum_expert"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                </div>
                <div class="col-6">
                  <multiselect
                    @search-change="fetchExpert"
                    class="mb-2"
                    id="input-grp-29"
                    v-model="form.other_experts"
                    :options="forum_expert"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                </div>

                <b-form-group
                  id="input-31"
                  label-for="input-grp-31"
                  label="Sub Speciality"
                  class="col-6"
                >
                  <multiselect
                    id="input-grp-31"
                    v-model="form.sub_specialities"
                    :options="forum_subspeciality"
                    :multiple="true"
                    track-by="id"
                    label="name"
                  >
                  </multiselect>
                </b-form-group>
                <b-form-group
                  id="input-32"
                  label-for="input-grp-32"
                  label="Forum Manager"
                  class="col-6"
                >
                  <multiselect
                    id="input-grp-32"
                    v-model="form.forum_manager"
                    :options="discussion_forum_manager"
                    :multiple="true"
                    track-by="id"
                    label="email"
                  >
                  </multiselect>
                </b-form-group>
                <div
                  v-for="(c, i) in form.forum_contribution"
                  :key="i"
                  class="col-lg-12"
                  style="
                    display: grid;
                    grid-template-columns: 1fr 2fr 0.2fr;
                    grid-gap: 20px;
                  "
                >
                  <b-form-group
                    label="Contribution Title"
                    label-for="contri_type"
                  >
                    <b-form-select
                      id="contri_type"
                      v-model="c.forum_contribution_title"
                      value-field="id"
                      text-field="title"
                      :options="contribution_type"
                      :key="i"
                      :multiple="false"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Contributors"
                    label-for="contri_member_type"
                    v-if="c.forum_contribution_title != ''"
                  >
                    <multiselect
                      id="contri_member_type"
                      v-model="c.member_type"
                      :options="contribution_member"
                      :multiple="true"
                      track-by="id"
                      label="name"
                      :key="i"
                      @search-change="fetchForumContributionMember"
                    >
                    </multiselect>
                  </b-form-group>
                  <div style="margin-top: 34px">
                    <b-button
                      variant="success"
                      class="mr-3 btn-sm"
                      @click="addContribution"
                      :disabled="c.forum_contribution_type == ''"
                      v-if="form.forum_contribution.length - 1 == i"
                      >+</b-button
                    >
                    <b-button
                      variant="danger"
                      class="btn-sm"
                      @click="removeContribution(i)"
                      >-</b-button
                    >
                  </div>
                </div>
                <b-form-group id="input-group-8" class="col-12">
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox
                      @input="changeKnowledgeAcademy"
                      v-model="form.is_knowledge_academy_active"
                      >Is Knowledge Academy Active</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.is_open_forum"
                      >Is Open Forum
                    </b-form-checkbox>
                    <b-form-checkbox v-model="form.show_followers"
                      >Show Followers
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-if="$route.name == 'edit-forum'"
                      v-model="form.is_homepage_custom"
                      @change="changeCustomForum"
                      >Is Custom Homepage
                    </b-form-checkbox>
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-9"
                  class="col-12"
                  v-if="form.is_homepage_custom"
                >
                  <label for="input-9"
                    >HTML File Content <span style="color: red">*</span></label
                  >
                  <b-form-textarea
                    id="input-9"
                    v-model="form.html_content"
                    placeholder="Enter HTML File Content"
                    rows="10"
                    :class="{
                      'is-invalid': submitted && $v.form.html_content.$error,
                    }"
                  >
                  </b-form-textarea>
                  <div
                    v-if="submitted && !$v.form.html_content.required"
                    class="invalid-feedback"
                  >
                    HTML Content is required when Custom Forum is checked.
                  </div>
                </b-form-group>
                <template v-if="isKnowledgeAcademy">
                  <b-form-group
                    id="input-group-9"
                    label="Knowledge Academy Name"
                    label-for="input-9"
                    class="col-6 position-relative"
                  >
                    <b-form-input
                      id="input-9"
                      v-model="form.knowledge_academy_name"
                      placeholder="Enter Knowledge Academy Name"
                      :maxlength="250"
                    >
                    </b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.knowledge_academy_name"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success':
                            form.knowledge_academy_name.length !== 250,
                          'badge-danger':
                            form.knowledge_academy_name.length === 250,
                        }"
                      >
                        You typed
                        {{ form.knowledge_academy_name.length }} out of 250
                        chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    id="input-group-10"
                    label="Knowledge Academy Header Text"
                    label-for="input-10"
                    class="col-6 position-relative"
                  >
                    <b-form-input
                      id="input-10"
                      :maxlength="250"
                      v-model="form.knowledge_academy_header_text"
                      placeholder="Enter Knowledge Academy Header Text"
                    ></b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.knowledge_academy_header_text"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success':
                            form.knowledge_academy_header_text.length !== 250,
                          'badge-danger':
                            form.knowledge_academy_header_text.length === 250,
                        }"
                      >
                        You typed
                        {{ form.knowledge_academy_header_text.length }} out of
                        250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    id="input-group-11"
                    label="Knowledge Academy Banner Image [Upload Max File Size : 2MB]"
                    class="col-6"
                    label-for="input-11"
                  >
                    <b-form-file
                      accept=".jpg,.jpeg,.png,.svg"
                      placeholder="Choose a file or drop it here..."
                      @change="
                        readFile($event, 'knowledge_academy_banner_image')
                      "
                    ></b-form-file>
                    <template
                      v-if="
                        $route.name == 'edit-forum' &&
                        edit.knowledge_academy_banner_image_url
                      "
                    >
                      <img
                        :src="edit.knowledge_academy_banner_image_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="knowledge_academy_banner_image_url">
                      <img
                        :src="knowledge_academy_banner_image_url"
                        width="128px"
                        height="128px"
                        ref="knowledge_academy_banner_image_url"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group
                    id="input-group-12"
                    class="col-6"
                    label="Thumbnail Image Banner Image Mobile [Upload Max File Size : 2MB]"
                    label-for="input-12"
                  >
                    <b-form-file
                      accept=".jpg,.jpeg,.png,.svg"
                      placeholder="Choose a file or drop it here..."
                      @change="
                        readFile(
                          $event,
                          'knowledge_academy_banner_image_mobile'
                        )
                      "
                    ></b-form-file>
                    <template
                      v-if="
                        $route.name == 'edit-forum' &&
                        edit.knowledge_academy_banner_image_mobile_url
                      "
                    >
                      <img
                        :src="edit.knowledge_academy_banner_image_mobile_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="knowledge_academy_banner_image_mobile_url">
                      <img
                        :src="knowledge_academy_banner_image_mobile_url"
                        width="128px"
                        height="128px"
                        ref="knowledge_academy_banner_image_mobile_url"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group
                    id="input-group-13"
                    label="Knowledge Academy to Address"
                    label-for="input-13"
                    class="col-6"
                  >
                    <b-form-input
                      id="input-13"
                      v-model="form.knowledge_academy_to_address"
                      placeholder="Enter Knowledge Academy to Address"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-14"
                    label="Interested in Grant Text"
                    label-for="input-14"
                    class="col-6"
                  >
                    <b-form-input
                      id="input-14"
                      v-model="form.interested_in_grant_text"
                      placeholder="Enter Interested in Grant Text"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-15"
                    label="Reaching Out Text"
                    label-for="input-15"
                    class="col-6"
                  >
                    <b-form-input
                      id="input-15"
                      v-model="form.reaching_out_text"
                      placeholder="Enter Reacing Out Text"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-16"
                    label="External Link"
                    label-for="input-16"
                    class="col-6"
                  >
                    <b-form-input
                      id="input-16"
                      v-model="form.external_link"
                      placeholder="Enter External Link"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-30"
                    label="Knowledge Academy Thankyou Message"
                    label-for="input-30"
                    class="col-12 position-relative"
                  >
                    <b-form-input
                      id="input-30"
                      :maxlength="250"
                      v-model="form.knowledge_academy_thank_you_message"
                      placeholder="Enter Knowledge Academy Thankyou Message"
                    ></b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.knowledge_academy_thank_you_message"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success':
                            form.knowledge_academy_thank_you_message.length !==
                            250,
                          'badge-danger':
                            form.knowledge_academy_thank_you_message.length ===
                            250,
                        }"
                      >
                        You typed
                        {{ form.knowledge_academy_thank_you_message.length }}
                        out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                </template>
              </div>
              <template v-if="$route.name == 'edit-forum'">
                <h6 class="mt-4">Forum Tabs:</h6>
                <div v-for="(data, i) in forum_tabs" :key="i">
                  <label>Label for {{ i }} :</label>
                  <div class="row">
                    <div class="col-md-4">
                      <b-form-group label-for="case">
                        <b-form-input
                          id="case"
                          type="number"
                          v-model="data.index"
                          placeholder="Index"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <b-form-group
                        id="input-group-1"
                        label-for="input-1"
                        class="mb-0"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="data.label"
                          placeholder="Enter Label"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="col-md-4"
                      style="
                        display: flex;
                        align-items: start;
                        padding-top: 10px;
                      "
                    >
                      <div style="display: flex; gap: 10px">
                        <b-form-checkbox v-model="data.active"
                          >Active</b-form-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <b-form-group
                  id="input-group-9"
                  label="CTA (Call to Actions)"
                  label-for="input-9"
                  class="mb-0"
                >
                  <div class="row" v-for="(data, i) in cta_data" :key="i">
                    <b-form-group class="col-3">
                      <b-form-input
                        id="input-30"
                        v-model="data.label"
                        :placeholder="data.label"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-3">
                      <b-form-input
                        id="input-30"
                        v-model="data.text"
                        placeholder="Text"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-3">
                      <b-form-input
                        id="input-30"
                        v-model="data.link"
                        placeholder="Link"
                      ></b-form-input>
                    </b-form-group>

                    <div
                      class="col-3"
                      style="
                        display: flex;
                        align-items: start;
                        padding-top: 10px;
                      "
                    >
                      <div style="display: flex; gap: 10px">
                        <b-form-checkbox v-model="data.active"
                          >Active</b-form-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </b-form-group>
                <div class="row">
                  <b-form-group
                    class="col-6 position-relative"
                    id="input-group-5"
                    label="Meta Title"
                    label-for="input-5"
                  >
                    <b-form-input
                      id="input-5"
                      :maxlength="190"
                      v-model="form.meta_title"
                      placeholder="Enter Meta Title"
                    ></b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.meta_title"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_title.length !== 190,
                          'badge-danger': form.meta_title.length === 190,
                        }"
                      >
                        You typed
                        {{ form.meta_title.length }} out of 190 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="col-6 position-relative"
                    label="Meta Keywords"
                    label-for="input-7"
                  >
                    <b-form-input
                      id="input-7"
                      :maxlength="250"
                      v-model="form.meta_keywords"
                      placeholder="Enter Meta Keywords"
                    >
                    </b-form-input>
                    <div class="text-right">
                      <p
                        v-if="form.meta_keywords"
                        class="badge position-absolute"
                        style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_keywords.length !== 250,
                          'badge-danger': form.meta_keywords.length === 250,
                        }"
                      >
                        You typed
                        {{ form.meta_keywords.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                </div>
                <b-form-group
                  id="input-group-6"
                  label="Meta Description"
                  label-for="input-6"
                  class="mb-4 position-relative"
                >
                  <b-form-textarea
                    id="input-6"
                    v-model="form.meta_description"
                    placeholder="Enter Meta Description"
                    rows="3"
                    :maxlength="400"
                  ></b-form-textarea>
                  <div class="text-right">
                    <p
                      v-if="form.meta_description"
                      class="badge position-absolute"
                      style="top: 4px; right: 13px"
                      :class="{
                        'badge-success': form.meta_description.length !== 400,
                        'badge-danger': form.meta_description.length === 400,
                      }"
                    >
                      You typed
                      {{ form.meta_description.length }} out of 400 chars.
                    </p>
                  </div>
                </b-form-group>
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox v-model="form.show_series_design"
                      >Show Series Design
                    </b-form-checkbox>
                    <b-form-checkbox v-model="form.show_landing_page"
                      >Show Landing page
                    </b-form-checkbox>
                  </div>
                  <div v-if="form.show_landing_page">
                    <b-form-group>
                      <div
                  class="d-flex justify-content-start align-items-center mb-2"
                >
                  <label class="mb-0" style="font-weight: 500"
                    >Email HTML Content <span style="color: red">*</span></label
                  >
                  <span
                    class="badge badge-primary ml-3 p-1"
                    style="cursor: pointer"
                    @click="formatCode"
                    v-if="form.landing_page_html"
                    >Format Code</span
                  >
                </div>
                <ace-editor
                  v-model="form.landing_page_html"
                  @init="editorInit"
                  lang="html"
                  theme="chrome"
                  width="100%"
                  height="1000"
                  class="mb-3"
                ></ace-editor>
                </b-form-group>
                    <div class="d-flex align-items-center mb-1">
                      <i class="mdi mdi-file-plus-outline mr-2"></i>
                      <h6 class="mb-0">Custom Fields</h6>
                    </div>
                    <div class="row" v-for="(reg, i) in form.custom_fields" :key="i">
                      {{custom_fields}}
                      <b-form-group class="col-5" label="Label" label-for="adv-1">
                        <b-form-input id="adv-1" v-model="reg.label" :placeholder="reg.label"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-5" label="Placeholder" label-for="adv-1">
                        <b-form-input id="adv-1" v-model="reg.placeholder" :placeholder="reg.placeholder"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-2 ac-req-check">
                        <b-form-checkbox value="on" unchecked-value="off" v-model="reg.required">Required</b-form-checkbox>
                        <b-form-checkbox value="on" unchecked-value="off" v-model="reg.active">Active</b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                <b-form-group>

                </b-form-group>
              </template>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                ><span v-if="this.$route.name == 'add-forum'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import forumMixin from "../../../mixins/ModuleJs/forum";
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  mixins: [MixinRequest, forumMixin],
  data() {
    return {
      submitted: false,
      title1: "Add Forum",
      title2: "Edit Forum",
      items: [
        {
          text: "Back",
          href: "/forum",
        },
        {
          text: "Data",
        },
      ],
      editor: ClassicEditor,
    };
  },
  validations: {
    form: {
      country: {
        required: requiredIf(
          (form) =>
            form.geographic_type == "country" ||
            form.geographic_type != "country"
        ),
      },
      city: { required: requiredIf((form) => form.geographic_type == "city") },
      state: {
        required: requiredIf(
          (form) =>
            form.geographic_type == "state" || form.geographic_type == "city"
        ),
      },
      name: { required },
      link_name: { required },
      forum_type: { required },
      partner_id: {
        required: requiredIf((form) => form.forum_type == "partner"),
      },
      forum_visibility: { required },
      privacy_rule: {
        required: requiredIf((form) => form.forum_visibility == "private"),
      },
      rule_type_password: {
        required: requiredIf(
          (form) =>
            form.privacy_rule.includes("password") &&
            form.forum_visibility == "private"
        ),
      },
      html_content: {
        required: requiredIf((form) => form.is_homepage_custom == 1),
      },
      landing_page_html: {
        required: requiredIf((form) => form.show_landing_page == 1),
      },
    },
  },
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
};
</script>
