<template lang="">
  <Layout>
    <template>
      <PageHeader :title="title" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label>Name <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Name"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && $v.form.name.$error,
                  }"
                ></b-form-input>
                <!-- <div
                  v-if="submitted && !$v.form.name.required"
                  class="invalid-feedback"
                >
                  name is required.
                </div> -->
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label class="">Image <span style="color: red">*</span></label>
                <b-form-group
                  id="input-group-icon"
                  label-for="input-icon"
                  class=""
                >
                  <b-form-file
                    id="input-icon"
                    accept="image/*"
                    @change="readFile($event, 'logo_image')"
                    ref="logo_image"
                    placeholder="Choose a file [Upload Max File Size : 2MB]"
                  >
                  </b-form-file>
                </b-form-group>
                <template v-if="logo_image_url">
                  <img
                    :src="logo_image_url"
                    width="128px"
                    height="128px"
                    style="object-fit: contain; margin-top: 5px"
                  />
                </template>
              </b-form-group>

              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import InstituteMixin from "../../../mixins/ModuleJs/institute-master";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, InstituteMixin],
  data() {
    return {
      title: "EDIT INSTITUTE",
      items: [
        {
          text: "Back",
          href: "/institute-master",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      name: { required },
    },
  },
};
</script>
